<template>
  <f7-navbar>
    <f7-nav-left>
      <f7-link v-if="type === 'back'" @click.stop="$h.goBack()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>
      <LeftPanelNavigationComponent v-else />
    </f7-nav-left>
    <f7-nav-title> {{ title }} </f7-nav-title>
    <f7-nav-right>
      <ChatNavigationComponent v-if="!hidechat" />

      <f7-link v-if="search === true" class="searchbar-enable" data-searchbar=".cellar-search">
        <font-awesome-icon :icon="['far', 'search']" fixed-width />
      </f7-link>
    </f7-nav-right>
    <f7-searchbar v-if="search === true" class="cellar-search" expandable :placeholder="$t.getTranslation('LBL_SEARCH')"></f7-searchbar>
  </f7-navbar>
</template>

<script>
import { defineComponent, computed, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

// import ChatNavigationComponent from "@/components/chat/ChatNavigationComponent.vue";
// import LeftPanelNavigationComponent from "@/components/navigations/LeftPanelNavigationComponent.vue";

const ChatNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "chat-nav" */ /* webpackMode: "lazy" */ "@/components/chat/ChatNavigationComponent.vue"));
const LeftPanelNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "left-panel-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/LeftPanelNavigationComponent.vue"));

export default defineComponent({
  name: "CommonNavigationComponent",
  components: {
    ChatNavigationComponent,
    LeftPanelNavigationComponent,
  },
  props: {
    title: String,
    type: String,
    search: { type: Boolean, default: true },
    hidechat: { type: Boolean, default: false },
  },
  setup() {
    const store = useStore();
        
    const activeLink = computed(() => store.getters["page/getActiveLink"]);

    return {
      activeLink,
    };
  },
});
</script>
